const words = {
    "words": [
        "bachi",
        "joerg",
        "tribi",
        "simon",
        "alpen",
        "berge",
        "piste",
        "pizza",
        "skier",
        "kater",
        "loewe",
        "tabak",
        "cyril",
        "maesi",
        "biere",
        "marty"
    ],
    "valid": [
        "aalen",
        "abart",
        "abbat",
        "abbau",
        "abbog",
        "abend",
        "abgab",
        "abgas",
        "abhob",
        "abhol",
        "abkam",
        "ablag",
        "abort",
        "abruf",
        "absah",
        "absud",
        "abtat",
        "abtei",
        "abtun",
        "abweg",
        "abwog",
        "abzog",
        "abzug",
        "achse",
        "acker",
        "acryl",
        "adams",
        "adeln",
        "adels",
        "adieu",
        "adler",
        "adlig",
        "affen",
        "affig",
        "after",
        "agave",
        "agent",
        "agrar",
        "ahmen",
        "ahnen",
        "ahorn",
        "akten",
        "aktie",
        "aktiv",
        "aktor",
        "alarm",
        "alben",
        "album",
        "alert",
        "alias",
        "alibi",
        "allee",
        "allwo",
        "allzu",
        "alpen",
        "alpha",
        "alpin",
        "altar",
        "alten",
        "alter",
        "ammen",
        "ampel",
        "amsel",
        "amten",
        "anbau",
        "anbei",
        "anbot",
        "andre",
        "angab",
        "angel",
        "angst",
        "anhob",
        "ankam",
        "anker",
        "anmut",
        "anruf",
        "ansah",
        "antat",
        "antik",
        "antun",
        "anzog",
        "anzug",
        "apart",
        "apfel",
        "april",
        "apsis",
        "arche",
        "areal",
        "arena",
        "arier",
        "armee",
        "armen",
        "armut",
        "aroma",
        "arsch",
        "arsen",
        "arten",
        "artig",
        "asche",
        "asien",
        "asket",
        "aspik",
        "assel",
        "aster",
        "astro",
        "atlas",
        "atmen",
        "atoll",
        "audio",
        "augen",
        "autor",
        "axiom",
        "baden",
        "bahre",
        "bambi",
        "banal",
        "bande",
        "banjo",
        "barde",
        "baron",
        "barst",
        "basal",
        "basar",
        "basis",
        "batik",
        "bauch",
        "bauen",
        "bauer",
        "beben",
        "beere",
        "begab",
        "beide",
        "beige",
        "bekam",
        "belag",
        "beleg",
        "belud",
        "berge",
        "beruf",
        "besah",
        "besen",
        "beste",
        "beten",
        "beton",
        "beuge",
        "beule",
        "beute",
        "bevor",
        "bewog",
        "bezug",
        "bibel",
        "biber",
        "biene",
        "biest",
        "binde",
        "binse",
        "birgt",
        "birke",
        "birne",
        "bitte",
        "biwak",
        "blank",
        "blase",
        "blass",
        "blatt",
        "blech",
        "blich",
        "blick",
        "blieb",
        "blies",
        "blind",
        "blink",
        "blitz",
        "block",
        "blond",
        "bluff",
        "blume",
        "bluse",
        "boden",
        "bogen",
        "bohle",
        "bohne",
        "bombe",
        "bongo",
        "bonus",
        "bonze",
        "boots",
        "borke",
        "borte",
        "boten",
        "bowle",
        "boxen",
        "boxer",
        "brach",
        "brand",
        "braue",
        "braun",
        "braut",
        "brech",
        "breit",
        "brems",
        "brenn",
        "brett",
        "brief",
        "briet",
        "brise",
        "brite",
        "bruch",
        "brumm",
        "brust",
        "buben",
        "buhen",
        "bulle",
        "busch",
        "busen",
        "butan",
        "butze",
        "celli",
        "cello",
        "chaos",
        "chaot",
        "chlor",
        "choke",
        "chrom",
        "civil",
        "clown",
        "cobra",
        "codex",
        "comic",
        "couch",
        "coupe",
        "creme",
        "curry",
        "dabei",
        "dachs",
        "daher",
        "dahin",
        "damen",
        "damit",
        "dampf",
        "dandy",
        "daran",
        "darum",
        "datei",
        "daten",
        "datex",
        "dativ",
        "datum",
        "dauer",
        "daune",
        "davon",
        "davor",
        "debil",
        "degen",
        "deich",
        "dekan",
        "dekor",
        "delle",
        "delta",
        "demut",
        "depot",
        "derer",
        "desto",
        "devon",
        "devot",
        "dicht",
        "diele",
        "dildo",
        "dinar",
        "diode",
        "dirne",
        "diwan",
        "docht",
        "dogge",
        "dogma",
        "dohle",
        "dolch",
        "donau",
        "dosen",
        "dosis",
        "draht",
        "drall",
        "drama",
        "drang",
        "drauf",
        "dreck",
        "drift",
        "drink",
        "dritt",
        "droge",
        "druck",
        "dudel",
        "duell",
        "duett",
        "dumpf",
        "dunst",
        "durch",
        "durst",
        "dusch",
        "dusel",
        "duzen",
        "ebben",
        "ebene",
        "ebnen",
        "ecken",
        "eckig",
        "edukt",
        "ehren",
        "eiche",
        "eifer",
        "eigen",
        "eilen",
        "eilig",
        "eimer",
        "einen",
        "einig",
        "eisen",
        "eisig",
        "eitel",
        "eiter",
        "ekeln",
        "eklat",
        "eklig",
        "ekzem",
        "elend",
        "elfen",
        "elite",
        "ellen",
        "empor",
        "emsig",
        "enden",
        "engel",
        "engen",
        "enkel",
        "enorm",
        "enten",
        "enzym",
        "erbat",
        "erben",
        "erbin",
        "erbse",
        "erden",
        "erdig",
        "ergab",
        "erker",
        "erlag",
        "ernst",
        "ernte",
        "erzog",
        "esche",
        "esser",
        "essig",
        "etage",
        "ethik",
        "ethos",
        "eulen",
        "euter",
        "exakt",
        "extra",
        "fabel",
        "faden",
        "fahne",
        "fahrt",
        "falke",
        "falle",
        "falls",
        "falte",
        "famos",
        "fango",
        "farbe",
        "fasan",
        "faser",
        "fatal",
        "fatum",
        "fauna",
        "faust",
        "faxen",
        "fazit",
        "fecht",
        "feder",
        "fegen",
        "feger",
        "fehde",
        "feier",
        "feile",
        "feind",
        "felge",
        "ferse",
        "fesch",
        "feuer",
        "fibel",
        "fiber",
        "fidel",
        "figur",
        "filet",
        "final",
        "finit",
        "finne",
        "finte",
        "firma",
        "first",
        "fisch",
        "fixen",
        "fixer",
        "fjord",
        "flach",
        "flair",
        "flaum",
        "fleck",
        "flink",
        "flirt",
        "flora",
        "floss",
        "flott",
        "fluch",
        "flugs",
        "fluid",
        "fluor",
        "fluss",
        "focht",
        "fokus",
        "folge",
        "folie",
        "foren",
        "forst",
        "forum",
        "fotze",
        "foyer",
        "frack",
        "frage",
        "franc",
        "freak",
        "frech",
        "fremd",
        "fress",
        "freud",
        "frist",
        "fromm",
        "front",
        "frost",
        "frust",
        "fuchs",
        "fuder",
        "fugen",
        "fuhre",
        "furie",
        "fusel",
        "futur",
        "gabel",
        "galle",
        "garbe",
        "garde",
        "garni",
        "gasse",
        "gassi",
        "gatte",
        "gauda",
        "gebar",
        "geben",
        "geber",
        "gebet",
        "gebot",
        "gegen",
        "gehen",
        "geier",
        "geige",
        "geist",
        "gelee",
        "genau",
        "genie",
        "genom",
        "genre",
        "genug",
        "genus",
        "gerte",
        "geste",
        "getan",
        "getto",
        "getue",
        "gicht",
        "gilde",
        "ginko",
        "glanz",
        "glatt",
        "gleis",
        "gleit",
        "glich",
        "glied",
        "glimm",
        "glitt",
        "gnade",
        "gosse",
        "gouda",
        "gramm",
        "graph",
        "greif",
        "greis",
        "grell",
        "grenz",
        "griff",
        "grill",
        "grips",
        "groll",
        "grube",
        "gruft",
        "grund",
        "gulag",
        "gummi",
        "gunst",
        "gurke",
        "gusto",
        "haben",
        "hades",
        "hafen",
        "hafer",
        "hagel",
        "hager",
        "haken",
        "halde",
        "hallo",
        "halte",
        "handy",
        "hanse",
        "harem",
        "harfe",
        "hasel",
        "hasen",
        "hatte",
        "haube",
        "hauch",
        "hauen",
        "hauer",
        "haupt",
        "hebel",
        "heben",
        "heber",
        "hecht",
        "hegen",
        "heide",
        "helot",
        "henne",
        "herab",
        "heran",
        "herum",
        "herzu",
        "heuer",
        "heute",
        "hexen",
        "hexer",
        "hielt",
        "hilfe",
        "hilfs",
        "hilft",
        "hinab",
        "hinan",
        "hindu",
        "hinzu",
        "hirse",
        "hirte",
        "hitze",
        "hobby",
        "hobel",
        "hoden",
        "holen",
        "honig",
        "horde",
        "horst",
        "hosen",
        "hotel",
        "human",
        "humid",
        "humor",
        "humus",
        "hunde",
        "hunne",
        "hupen",
        "huren",
        "hurra",
        "husar",
        "hydra",
        "hymne",
        "ideal",
        "ideen",
        "idiot",
        "idyll",
        "igeln",
        "ikone",
        "iltis",
        "image",
        "imker",
        "immer",
        "immun",
        "indem",
        "indes",
        "index",
        "indio",
        "indiz",
        "infam",
        "innen",
        "innig",
        "insel",
        "intim",
        "intus",
        "ionen",
        "irden",
        "irren",
        "irrig",
        "islam",
        "jacht",
        "jacke",
        "jagen",
        "japan",
        "jeher",
        "jetzt",
        "joker",
        "jolle",
        "joule",
        "jubel",
        "juden",
        "jumbo",
        "junge",
        "juras",
        "juror",
        "juwel",
        "kabel",
        "kader",
        "kakao",
        "kalbs",
        "kalif",
        "kamel",
        "kamin",
        "kampf",
        "kanal",
        "kanne",
        "kanon",
        "kante",
        "kappe",
        "karat",
        "karre",
        "karte",
        "kasko",
        "kasse",
        "kasus",
        "kater",
        "kauen",
        "kegel",
        "kehle",
        "kelch",
        "kelle",
        "kerze",
        "kette",
        "keule",
        "kiepe",
        "kiosk",
        "kippe",
        "kirch",
        "kiste",
        "klage",
        "klamm",
        "klang",
        "klapp",
        "klebe",
        "kleid",
        "kleie",
        "klein",
        "klemm",
        "klick",
        "klima",
        "klipp",
        "klops",
        "klotz",
        "kluft",
        "knabe",
        "knack",
        "knall",
        "knapp",
        "knast",
        "knauf",
        "knick",
        "knien",
        "kniff",
        "knopf",
        "kobra",
        "kodex",
        "kohle",
        "kokon",
        "kolik",
        "komet",
        "komik",
        "komma",
        "konto",
        "kopie",
        "koran",
        "korps",
        "korso",
        "kosak",
        "krach",
        "kraft",
        "krake",
        "krank",
        "kranz",
        "krass",
        "kraus",
        "kraut",
        "krebs",
        "kreis",
        "krepp",
        "kreuz",
        "krieg",
        "krimi",
        "krise",
        "kroch",
        "krone",
        "kropf",
        "kross",
        "krume",
        "krumm",
        "kuben",
        "kubus",
        "kugel",
        "kuhle",
        "kunde",
        "kunst",
        "kupee",
        "kupon",
        "kuppe",
        "kuren",
        "kurie",
        "kurve",
        "laben",
        "labil",
        "labor",
        "lache",
        "lachs",
        "laden",
        "lader",
        "lagen",
        "lager",
        "lakai",
        "laken",
        "lamee",
        "lampe",
        "lande",
        "lanze",
        "larve",
        "laser",
        "lasso",
        "latex",
        "latte",
        "laube",
        "lauch",
        "lauer",
        "laune",
        "leben",
        "leber",
        "leder",
        "ledig",
        "legal",
        "legen",
        "leger",
        "lehne",
        "lehre",
        "leibt",
        "leier",
        "leine",
        "leise",
        "lemma",
        "lende",
        "lepra",
        "lesbe",
        "lesen",
        "leser",
        "letzt",
        "leute",
        "liane",
        "licht",
        "liebe",
        "liege",
        "liest",
        "ligen",
        "lilie",
        "limes",
        "limit",
        "linde",
        "linie",
        "linke",
        "links",
        "linse",
        "lippe",
        "liter",
        "litze",
        "lobby",
        "loben",
        "logik",
        "logis",
        "lokal",
        "losen",
        "loten",
        "lotos",
        "lotse",
        "lotto",
        "loyal",
        "luchs",
        "luder",
        "lugen",
        "lunge",
        "lunte",
        "luxus",
        "lymph",
        "lyrik",
        "macho",
        "macht",
        "macke",
        "madig",
        "mafia",
        "magen",
        "mager",
        "magie",
        "magma",
        "magst",
        "major",
        "makel",
        "makro",
        "malen",
        "maler",
        "malus",
        "malve",
        "manch",
        "manie",
        "manko",
        "manna",
        "mappe",
        "marge",
        "markt",
        "maske",
        "masse",
        "mauer",
        "media",
        "meile",
        "meise",
        "meist",
        "melde",
        "menge",
        "mensa",
        "messe",
        "meter",
        "meute",
        "miene",
        "miete",
        "mieze",
        "mikro",
        "milan",
        "milbe",
        "milch",
        "miliz",
        "mimik",
        "minen",
        "minus",
        "misch",
        "mitte",
        "mixen",
        "mixer",
        "mobil",
        "modul",
        "modus",
        "mogul",
        "moire",
        "mokka",
        "monat",
        "moped",
        "moral",
        "morse",
        "motel",
        "motiv",
        "motor",
        "motte",
        "motto",
        "muffe",
        "mulde",
        "multi",
        "mumie",
        "murks",
        "musik",
        "musst",
        "muten",
        "mutig",
        "mutti",
        "myrre",
        "nabel",
        "nacht",
        "nackt",
        "nadel",
        "nagel",
        "nagen",
        "nager",
        "nahen",
        "namen",
        "narbe",
        "nasen",
        "nativ",
        "natur",
        "nebel",
        "neben",
        "nebst",
        "neffe",
        "neger",
        "nelke",
        "netto",
        "neuer",
        "neunt",
        "nicht",
        "niere",
        "niete",
        "nimmt",
        "nobel",
        "nomen",
        "nonne",
        "norme",
        "notar",
        "noten",
        "notiz",
        "novum",
        "nudel",
        "nugat",
        "nutte",
        "nylon",
        "obhut",
        "oblag",
        "ocker",
        "odium",
        "offen",
        "oheim",
        "ohren",
        "oktan",
        "oktav",
        "olive",
        "olymp",
        "onkel",
        "opern",
        "opfer",
        "opium",
        "optik",
        "orbit",
        "orden",
        "order",
        "organ",
        "orgel",
        "orgie",
        "orkan",
        "orten",
        "osten",
        "oster",
        "otter",
        "ozean",
        "pacht",
        "paket",
        "palme",
        "panik",
        "panne",
        "pappe",
        "papst",
        "parat",
        "pasta",
        "paste",
        "pater",
        "pauke",
        "pause",
        "pedal",
        "pegel",
        "penis",
        "perle",
        "pfahl",
        "pfand",
        "pfarr",
        "pfeil",
        "pferd",
        "pfiff",
        "pflug",
        "pfote",
        "pfuhl",
        "pfund",
        "phase",
        "photo",
        "piano",
        "piezo",
        "pille",
        "pilot",
        "pinie",
        "pinne",
        "pirat",
        "piste",
        "pixel",
        "pizza",
        "platt",
        "platz",
        "plump",
        "pluto",
        "pokal",
        "poker",
        "polar",
        "polen",
        "polig",
        "polis",
        "polit",
        "polle",
        "polyp",
        "poren",
        "porno",
        "porti",
        "porto",
        "posse",
        "prall",
        "preis",
        "press",
        "pries",
        "prima",
        "prime",
        "prinz",
        "probe",
        "profi",
        "prosa",
        "prost",
        "prunk",
        "psalm",
        "pudel",
        "puder",
        "pulle",
        "pulli",
        "pumpe",
        "punkt",
        "puppe",
        "puter",
        "pylon",
        "qualm",
        "quant",
        "quark",
        "quart",
        "quarz",
        "quasi",
        "quell",
        "quere",
        "quirl",
        "quitt",
        "quote",
        "rache",
        "radar",
        "radau",
        "radio",
        "radon",
        "ragen",
        "rampe",
        "rapid",
        "rappe",
        "rasch",
        "rasen",
        "rasse",
        "raten",
        "ratte",
        "rauch",
        "raudi",
        "rauen",
        "raupe",
        "realo",
        "recht",
        "reden",
        "reell",
        "regal",
        "regel",
        "regen",
        "regie",
        "reich",
        "reihe",
        "reise",
        "remis",
        "rente",
        "reuig",
        "revue",
        "richt",
        "ricke",
        "riege",
        "riese",
        "riete",
        "rigid",
        "rille",
        "rinde",
        "rings",
        "rinne",
        "rippe",
        "riten",
        "robbe",
        "roden",
        "rolle",
        "rolli",
        "roman",
        "romme",
        "rondo",
        "rosen",
        "rosig",
        "rotor",
        "route",
        "rubel",
        "rubin",
        "ruder",
        "rufen",
        "rufer",
        "rugby",
        "ruhen",
        "ruhig",
        "rumpf",
        "runde",
        "rupie",
        "sache",
        "sacht",
        "sagen",
        "sahne",
        "saite",
        "salat",
        "salbe",
        "saldo",
        "salon",
        "salto",
        "salut",
        "salve",
        "samba",
        "samen",
        "sanft",
        "satan",
        "satin",
        "satyr",
        "sauce",
        "sauen",
        "sauer",
        "sauna",
        "schad",
        "schaf",
        "schah",
        "schal",
        "scham",
        "schar",
        "schau",
        "scheu",
        "schob",
        "schon",
        "schor",
        "schub",
        "schuf",
        "schuh",
        "schul",
        "schur",
        "sechs",
        "seele",
        "segel",
        "segen",
        "sehen",
        "seher",
        "seide",
        "seife",
        "seite",
        "selbe",
        "selig",
        "semit",
        "senat",
        "sende",
        "senil",
        "seren",
        "serie",
        "serum",
        "sesam",
        "sekte",
        "sicht",
        "siech",
        "sieht",
        "silbe",
        "sinus",
        "sippe",
        "sirup",
        "sitte",
        "skala",
        "skalp",
        "skier",
        "slawe",
        "smart",
        "socke",
        "sogar",
        "sohle",
        "solch",
        "solei",
        "solle",
        "somit",
        "sonde",
        "sonne",
        "sonor",
        "sonst",
        "sooft",
        "sorge",
        "sorte",
        "sowie",
        "spalt",
        "spann",
        "spant",
        "spatz",
        "speck",
        "speer",
        "sperr",
        "spezi",
        "spiel",
        "spien",
        "spike",
        "spind",
        "spion",
        "spitz",
        "spore",
        "sporn",
        "sport",
        "spott",
        "spreu",
        "sprit",
        "spule",
        "spurt",
        "staat",
        "stabs",
        "stach",
        "stadt",
        "stahl",
        "stall",
        "stamm",
        "stand",
        "stank",
        "starb",
        "stark",
        "starr",
        "start",
        "statt",
        "staub",
        "steak",
        "stech",
        "steck",
        "steif",
        "steig",
        "steil",
        "stein",
        "stell",
        "stemm",
        "stern",
        "stete",
        "stets",
        "stich",
        "stieg",
        "stiel",
        "stier",
        "stift",
        "still",
        "stimm",
        "stirn",
        "stock",
        "stoff",
        "stola",
        "stolz",
        "stopp",
        "straf",
        "streb",
        "streu",
        "stroh",
        "strom",
        "stube",
        "stuck",
        "stufe",
        "stuhl",
        "stumm",
        "stunk",
        "sturm",
        "sturz",
        "stuss",
        "stute",
        "suche",
        "sucht",
        "suite",
        "summe",
        "sumpf",
        "super",
        "suppe",
        "sushi",
        "szene",
        "tabak",
        "tadel",
        "tafel",
        "tagen",
        "tages",
        "taiga",
        "takel",
        "taler",
        "talon",
        "tango",
        "tanne",
        "tante",
        "tapet",
        "tarif",
        "tasse",
        "taste",
        "tatze",
        "taube",
        "tauch",
        "tauen",
        "taxen",
        "teeei",
        "teich",
        "teile",
        "teint",
        "telex",
        "tempo",
        "tenne",
        "tenor",
        "teuer",
        "theke",
        "thema",
        "these",
        "thron",
        "tiger",
        "tilde",
        "tinte",
        "titan",
        "titel",
        "toast",
        "toben",
        "todes",
        "tonne",
        "topas",
        "torso",
        "torte",
        "tosen",
        "total",
        "toten",
        "trafo",
        "trage",
        "trakt",
        "trank",
        "traum",
        "treff",
        "treib",
        "trend",
        "trenn",
        "treue",
        "trias",
        "trick",
        "trieb",
        "trink",
        "trist",
        "tritt",
        "troll",
        "tropf",
        "tross",
        "trost",
        "trott",
        "trotz",
        "truhe",
        "trunk",
        "trupp",
        "tuend",
        "tulpe",
        "tumor",
        "tunte",
        "turbo",
        "tusch",
        "tuten",
        "tutor",
        "typen",
        "typus",
        "ulken",
        "ulkig",
        "ultra",
        "umbau",
        "umgab",
        "umher",
        "umhin",
        "umkam",
        "umsah",
        "umtun",
        "umweg",
        "umzog",
        "umzug",
        "unfug",
        "union",
        "unken",
        "unmut",
        "unrat",
        "unruh",
        "unser",
        "unsre",
        "unten",
        "unter",
        "urahn",
        "uralt",
        "urban",
        "vater",
        "vegan",
        "velin",
        "venen",
        "venus",
        "video",
        "viert",
        "vikar",
        "villa",
        "viper",
        "viren",
        "virus",
        "visum",
        "vital",
        "vlies",
        "vogel",
        "vokal",
        "volks",
        "vorab",
        "voran",
        "vorig",
        "votum",
        "waage",
        "wache",
        "wachs",
        "wacht",
        "waden",
        "waffe",
        "wagen",
        "wagon",
        "waise",
        "walze",
        "wange",
        "wanne",
        "wanst",
        "wanze",
        "waren",
        "warte",
        "warum",
        "warze",
        "wasch",
        "waten",
        "watte",
        "weben",
        "weber",
        "wedel",
        "weder",
        "wehen",
        "weich",
        "weide",
        "weihe",
        "weise",
        "weite",
        "welch",
        "welle",
        "welpe",
        "wende",
        "wenig",
        "werbe",
        "werft",
        "werks",
        "werte",
        "wesen",
        "wespe",
        "weste",
        "wette",
        "wicke",
        "wider",
        "wiese",
        "wieso",
        "wille",
        "wirbt",
        "wirft",
        "wirst",
        "wisch",
        "witwe",
        "wobei",
        "woche",
        "wodka",
        "woher",
        "wohin",
        "wolfs",
        "wolke",
        "wolle",
        "womit",
        "wonne",
        "woran",
        "worin",
        "worum",
        "wotan",
        "wovon",
        "wovor",
        "wrack",
        "wrang",
        "wuchs",
        "wucht",
        "wulst",
        "wunde",
        "wurde",
        "wurst",
        "wusch",
        "yacht",
        "zange",
        "zebra",
        "zeche",
        "zehnt",
        "zeige",
        "zeile",
        "zelle",
        "zeter",
        "zicke",
        "ziege",
        "zille",
        "zirka",
        "zitat",
        "zitze",
        "zivil",
        "zobel",
        "zonen",
        "zotig",
        "zuber",
        "zucht",
        "zudem",
        "zugab",
        "zugig",
        "zumal",
        "zunft",
        "zunge",
        "zuruf",
        "zusah",
        "zutat",
        "zutun",
        "zuvor",
        "zuzog",
        "zuzug",
        "zwang",
        "zweck",
        "zweig",
        "zweit",
        "zwerg",
        "zwirn",
        "zwist",
        "zyste",
        "aebte",
        "aehre",
        "aesen",
        "aexte",
        "boeig",
        "bloed",
        "bloss",
        "buero",
        "boete",
        "buett",
        "busse",
        "diaet",
        "daemm",
        "duene",
        "duenn",
        "duerr",
        "duese",
        "foehn",
        "faehr",
        "fuell",
        "floss",
        "floez",
        "fuenf",
        "frass",
        "frueh",
        "fraes",
        "geiss",
        "giess",
        "glueh",
        "goere",
        "gruen",
        "gross",
        "gruss",
        "guete",
        "huebe",
        "heiss",
        "hoefe",
        "hueft",
        "hoehe",
        "hiess",
        "haeme",
        "huene",
        "huete",
        "kuehe",
        "kuehl",
        "kuehn",
        "kloss",
        "klaer",
        "kaese",
        "laedt",
        "luege",
        "liess",
        "laerm",
        "loess",
        "loewe",
        "muede",
        "menue",
        "moege",
        "muell",
        "muenz",
        "maerz",
        "musse",
        "moewe",
        "naehe",
        "naehr",
        "noete",
        "oefen",
        "oelen",
        "oelig",
        "pruef",
        "puett",
        "ruebe",
        "rueck",
        "ruege",
        "raete",
        "saeen",
        "saege",
        "saehe",
        "saele",
        "sosse",
        "spass",
        "spaeh",
        "spuel",
        "spuer",
        "spaet",
        "stoss",
        "stoer",
        "saeue",
        "tuell",
        "toene",
        "trueb",
        "traeg",
        "taete",
        "uebel",
        "ueben",
        "ueber",
        "weiss",
        "woege",
        "wuehl",
        "waerm",
        "wuest",
        "zoege",
        "zaehl",
        "zuend"
    ]
};
export default words;
